<template>
  <v-data-table
    :headers="headers"
    :items="brands"
    :search="search"
    :items-per-page="-1"
    class="br-16 elevation-1"
    sort-by="name"
  >
    <template v-slot:top>
      <div class="d-flex justify-space-between align-center br-16 pa-4">
        <div class="d-flex align-center">
          <v-text-field
            v-model="search"
            class="br-8 mr-8"
            placeholder="Filter brands"
            label="Name"
            style="max-width: 350px"
            prepend-inner-icon="search"
            hide-details
            outlined
            dense
          />
          <v-checkbox
            v-model="show_individual_brands"
            label="Show Individual Brands"
          />
        </div>
        <v-btn
          color="yellow"
          rounded
          :to="{ name: 'admin-brands-new' }"
          class="mr-0"
        >
          <v-icon color="black" class="mr-2">mdi-plus</v-icon>Add new
          brand</v-btn
        >
      </div>
    </template>
    <template v-slot:[`header.facebook_url`]>
      <v-icon color="#3b5998">mdi-facebook</v-icon>
    </template>
    <template v-slot:[`header.instagram_url`]>
      <v-icon color="#833AB4">mdi-instagram</v-icon>
    </template>
    <template v-slot:[`header.twitter_url`]>
      <v-icon color="#1DA1F2">mdi-twitter</v-icon>
    </template>

    <template v-slot:[`item.preview`]="{ item }">
      <a :href="preview_url(item.id)" target="_blank" class="no-text-decoration"
        ><v-icon>mdi-eye-outline</v-icon></a
      >
    </template>
    <template v-slot:[`item.brand_type`]="{ item }">
      <p class="text-capitalize mb-0">
        {{ item.brand_type === "single_shop" ? "Individual" : item.brand_type }}
      </p>
    </template>
    <!-- <template v-slot:[`item.logo`]="{ item }">
      <div
        v-if="item.logo"
        class="logo-preview"
        :style="`background-image: url('${
          item.logo
        }'), url(${require('@/assets/images/square-bg.jpg')})`"
      ></div>
    </template> -->
    <template v-slot:[`item.url`]="{ item }">
      <a :href="item.url" target="_blank" class="black--text">{{ item.url }}</a>
    </template>
    <template v-slot:[`item.theme_color`]="{ item }">
      <div
        class="color-preview"
        :style="`background-color: ${item.theme_color}`"
      />
    </template>
    <template v-slot:[`item.facebook_url`]="{ item }">
      <v-btn
        v-if="item.facebook_url"
        small
        icon
        :href="item.facebook_url"
        target="_blank"
        ><v-icon>mdi-open-in-new</v-icon></v-btn
      ><span v-else>N/A</span>
    </template>
    <template v-slot:[`item.instagram_url`]="{ item }">
      <v-btn
        v-if="item.instagram_url"
        small
        icon
        :href="item.instagram_url"
        target="_blank"
        ><v-icon>mdi-open-in-new</v-icon></v-btn
      ><span v-else>N/A</span>
    </template>
    <template v-slot:[`item.twitter_url`]="{ item }">
      <v-btn
        v-if="item.twitter_url"
        small
        icon
        :href="item.twitter_url"
        target="_blank"
        ><v-icon>mdi-open-in-new</v-icon></v-btn
      ><span v-else>N/A</span>
    </template>
    <template v-slot:[`item.shop_id`]="{ item }">
      <router-link
        v-if="item.shop_id"
        class="black--text"
        :to="{ name: 'admin-shops-id', params: { id: item.shop_id } }"
        >{{ item.shop_id }}</router-link
      >
    </template>
    <template v-slot:[`item.settings`]="{ item }">
      <v-btn
        small
        icon
        :to="{ name: 'admin-brands-id-edit', params: { brand_id: item.id } }"
        ><v-icon>mdi-table-cog</v-icon></v-btn
      >
    </template>
    <template v-slot:[`item.edit`]="{ item }">
      <v-btn
        small
        icon
        :to="{
          name: 'admin-brands-id-home-page',
          params: { brand_id: item.id },
        }"
        ><v-icon>mdi-pencil-outline</v-icon></v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
import { get_brands } from "@/requests";
import { mapActions } from "vuex";

export default {
  name: "AllBrands",
  data() {
    return {
      brands: [],
      search: null,
      show_individual_brands: false,
      headers: [
        // { text: "Logo", value: "logo", align: "center" },
        { text: "Name", value: "name" },
        {
          text: "Theme",
          value: "theme_color",
          align: "center",
          sortable: false,
        },
        {
          text: "Edit",
          value: "edit",
          align: "center",
          sortable: false,
        },
        {
          text: "Settings",
          value: "settings",
          align: "center",
          sortable: false,
        },
        { text: "Preview", value: "preview", align: "center", sortable: false },
        { text: "Contact", value: "contact", align: "left" },
        {
          text: "Type",
          value: "brand_type",
          filter: (value) =>
            this.show_individual_brands ? true : value !== "single_shop",
        },
        { text: "Shop", value: "shop_id" },
        { text: "URL", value: "url", align: "left", sortable: false },
        { text: "FB", value: "facebook_url", align: "center", sortable: false },
        {
          text: "Instagram",
          value: "instagram_url",
          align: "center",
          sortable: false,
        },
        {
          text: "Twitter",
          value: "twitter_url",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.set_selected_brand(null);
  },
  async mounted() {
    const { data: brands } = await get_brands();
    this.brands = brands;
  },
  methods: {
    ...mapActions("SuperAdminStore", ["set_selected_brand"]),
    preview_url(id) {
      const preview_url = process.env.VUE_APP_BRAND_PREVIEW_URL;
      return `${preview_url}/?brand_id=${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-preview {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0 auto;
}
.logo-preview {
  margin: auto;
  height: 56px;
  width: 200px;
  background-position: center center;
  background-repeat: initial;
  background-size: contain, auto;
}
</style>
