var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"br-16 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.brands,"search":_vm.search,"items-per-page":-1,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center br-16 pa-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"br-8 mr-8",staticStyle:{"max-width":"350px"},attrs:{"placeholder":"Filter brands","label":"Name","prepend-inner-icon":"search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-checkbox',{attrs:{"label":"Show Individual Brands"},model:{value:(_vm.show_individual_brands),callback:function ($$v) {_vm.show_individual_brands=$$v},expression:"show_individual_brands"}})],1),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"yellow","rounded":"","to":{ name: 'admin-brands-new' }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-plus")]),_vm._v("Add new brand")],1)],1)]},proxy:true},{key:"header.facebook_url",fn:function(){return [_c('v-icon',{attrs:{"color":"#3b5998"}},[_vm._v("mdi-facebook")])]},proxy:true},{key:"header.instagram_url",fn:function(){return [_c('v-icon',{attrs:{"color":"#833AB4"}},[_vm._v("mdi-instagram")])]},proxy:true},{key:"header.twitter_url",fn:function(){return [_c('v-icon',{attrs:{"color":"#1DA1F2"}},[_vm._v("mdi-twitter")])]},proxy:true},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"no-text-decoration",attrs:{"href":_vm.preview_url(item.id),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},{key:"item.brand_type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-capitalize mb-0"},[_vm._v(" "+_vm._s(item.brand_type === "single_shop" ? "Individual" : item.brand_type)+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"black--text",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.theme_color",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"color-preview",style:(("background-color: " + (item.theme_color)))})]}},{key:"item.facebook_url",fn:function(ref){
var item = ref.item;
return [(item.facebook_url)?_c('v-btn',{attrs:{"small":"","icon":"","href":item.facebook_url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_c('span',[_vm._v("N/A")])]}},{key:"item.instagram_url",fn:function(ref){
var item = ref.item;
return [(item.instagram_url)?_c('v-btn',{attrs:{"small":"","icon":"","href":item.instagram_url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_c('span',[_vm._v("N/A")])]}},{key:"item.twitter_url",fn:function(ref){
var item = ref.item;
return [(item.twitter_url)?_c('v-btn',{attrs:{"small":"","icon":"","href":item.twitter_url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_c('span',[_vm._v("N/A")])]}},{key:"item.shop_id",fn:function(ref){
var item = ref.item;
return [(item.shop_id)?_c('router-link',{staticClass:"black--text",attrs:{"to":{ name: 'admin-shops-id', params: { id: item.shop_id } }}},[_vm._v(_vm._s(item.shop_id))]):_vm._e()]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","to":{ name: 'admin-brands-id-edit', params: { brand_id: item.id } }}},[_c('v-icon',[_vm._v("mdi-table-cog")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","to":{
        name: 'admin-brands-id-home-page',
        params: { brand_id: item.id },
      }}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }